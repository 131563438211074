import React, { useState, useEffect } from "react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";

const BookingsTableComponent = ({ bookings, dataBreakdown }) => {
  const [filterTerms, setFilterTerms] = useState([]); 
  const [secondFilterTerms, secondFetFilterTerms] = useState([]); 
  const [ thirdFilterTerms, setThirdFilterTerms] = useState([]); 
  const [ fourthFilterTerms, setFourthFilterTerms] = useState([]); 
  const [ fifthFilterTerms, setFifthFilterTerms ] = useState([]);

  useEffect(() => {
    const filterAgencies = () => {
      const agencies = [
        ...new Set(bookings.map((b) => b.agency)),
      ].filter(Boolean);
      setFilterTerms(agencies); 
    }

  const filterStatus = () => {
    const statuses = [
      ...new Set(bookings.map((b) => b.status)),
    ].filter(Boolean);
    secondFetFilterTerms(statuses); 
  }

  const routeTypeFilter= () => {
    const route_types = [
      ...new Set(bookings.map((b) => b.route_type)),
    ].filter(Boolean);
    setThirdFilterTerms(route_types); 
  }
  const sessionFilter= () => {
    const sessions = [
      ...new Set(bookings.map((b) => b.trip_leg)),
    ].filter(Boolean);
    setFourthFilterTerms(sessions); 
  }
  const routesFilter= () => {
    const routes = [
      ...new Set(bookings.map((b) => b.route_code)),
    ].filter(Boolean);
    setFifthFilterTerms(routes); 
  }
  routeTypeFilter();
  filterAgencies();
  filterStatus();
  sessionFilter();
  routesFilter();
},[]);


  const headers = ["Booking Id","Passenger Name","Trip Date", "Client", "Route","Session", "Stage","Booking Status" ];
  const columns = [ "booking_code","passengerName","tripDate","agency","route_code","session","stage","status"]

  return (
    <>
      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={bookings || []}
        sortableColumns={[0, 1, 2, 3, 4, 5]}
        titleColumnIndex={0}
        searchEnabled={true}
        settingsColumn={false}
        titleClickable={true}
        filterEnabled={true}
        filterLabel={"Client"}
        filterOptions={filterTerms}
        titleClickableUrl="/dashboard/bookings"
        hideCreateButton = {false}
        createEntityLink="/dashboard/bookings/create"
        secondFilterEnabled={true}
        secondFilterLabel={"Status"}
        secondFilterOptions={secondFilterTerms}
        thirdFilterEnabled={true}
        thirdFilterLabel={"Route Category"}
        thirdFilterOptions={thirdFilterTerms}

        fourthFilterEnabled={true}
        fourthFilterLabel={"Session"}
        fourthFilterOptions={fourthFilterTerms}

        fifthFilterEnabled={true}
        fifthFilterLabel={"Routes"}
        fifthFilterOptions={fifthFilterTerms}

        dataBreakdown={dataBreakdown}
      />
    </>
  );
};



export default BookingsTableComponent;
