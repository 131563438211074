import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import {fetchOrgPeople}  from "@/apis/clients"
const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  
  const date = new Date(dateString);
  if (isNaN(date)) return dateString;
  
  return date.toLocaleString('en-GB', options).replace(',', '');
};

const OrgPeople = ({ id }) => {
  const [users, setUsers] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [filterTerms, setFilterTerms] = useState([]);

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const users = await fetchOrgPeople(token, id);
        if (users) {
          setUsers(users.people)
          setUserStats([
            { label: 'Total', value: users.people.length || 0 },
            { label: 'Active ', value: users.active_users || 0 },
            { label: 'Inactive ', value: users.inactive_users || 0 },
            { label: 'Nearing Expiry ', value: users.nearing_expiry || 0 },
          ]); 
          const agencies = [
            ...new Set(users.people.map((b) => b.agency)),
          ].filter(Boolean);

          setFilterTerms(agencies);

        } else {
          setAlert({
            status: true,
            message: "Unable to fetch users.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching users.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = ["Name", "Email", "Mobile Number","Index Number","Agency", "Account Status","Account Type", "Contract Expiry"];
  const columns = ["username", "email", "mobileNumber", "index_no","agency", "status", "account_type","contract_expiry"];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        statistics={userStats}
        data={users || []}
        sortableColumns={[0, 1, 2, 3, 4, 5]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        filterEnabled={true}
        filterLabel={"Agency"}
        filterOptions={filterTerms}
        settingsColumn={true}
        hideCreateButton={true}
        handleSettingsEditLink={`/dashboard/users`}
      />
    </>
  );
};



export default OrgPeople;
