import React, { useState, useEffect } from 'react';
import {
  Card,
  Input,
  Button,
  Typography,
  Select,
  Option,
  Spinner,
} from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import { fetchAvailableVehicles, updateRouteFunction } from "@/apis/maintenances";
import { fetchOrganisations } from "@/apis/clients"

export function RouteManagement({ formData,id, refreshData }) {
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [clients, setClients] = useState([]);
    const [routeData, setRouteData] = useState({
      route_code:formData.route_code,
      start_point_coordinates:formData.start_point_coordinates,
      end_point_coordinates:formData.end_point_coordinates,
      estimate_length:formData.estimate_length,
      billing_length:formData.billing_length,
      vehicle_id:formData.vehicle_id,
      client_id:formData.client_id,
      registration:formData.registration,
      fleet_number:formData.fleet_number,
      end_point:formData.end_point,
      start_point:formData.start_point,
      status:formData.status,
      id:formData.id,
    });
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });
    const [errors, setErrors] = useState({});
    
    useEffect(() => {
        setAlert({ status: false, message: '', type: '' });
        setLoading(true);
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const data = await fetchAvailableVehicles(token,formData.vehicle_id );
                if (data) {
                    setVehicles(data);
                } else {
                setAlert({
                    status: true,
                    message: "Unable to fetch available vehicles.",
                    type: 'error',
                });
                }
            } catch (error) {
                setAlert({
                status: true,
                message: 'We are having trouble fetching vailable vehicles. Contact admin.',
                type: 'error',
                });
            } finally {
                setLoading(false);
            }
        };
        const fetchClients = async () => {
            try {
                const token = localStorage.getItem('token');
                const data = await fetchOrganisations(token);
                if (data) {
                    setClients(data.data);
                } else {
                setAlert({
                    status: true,
                    message: "Unable to fetch available clients.",
                    type: 'error',
                });
                }
            } catch (error) {
                setAlert({
                status: true,
                message: 'We are having trouble fetching vailable clients. Contact admin.',
                type: 'error',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        fetchClients();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRouteData({ ...routeData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        console.log("name",name)
        console.log("value",value)
      setRouteData({ ...routeData, [name]: value });
    };

    const handleInputChangeWithValidation = (e) => {
        const { name, value } = e.target;

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

        if (name === 'registration') {
        const plateNumberPattern = /^[A-Za-z]{3}\d{3}[A-Za-z]{1}$/;
        if (!plateNumberPattern.test(value)) {
            setErrors((prevErrors) => ({
            ...prevErrors,
            registration: 'Invalid plate number format. Expected format: KAA000A'
            }));
        }
        }

        handleInputChange(e);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        const token = localStorage.getItem('token');
        try {
            const dataToSubmit = { ...routeData };
            delete dataToSubmit.registration;
            delete dataToSubmit.fleet_number;
        
          const createRoute = await updateRouteFunction(token, dataToSubmit);
          if(createRoute){
            setLoading(false);
            setAlert({
              status: true,
              type: 'success',
              message: 'Routes updated successfully.',
            });
          }else{
            setLoading(false);
            setAlert({
              status: true,
              type: 'error',
              message: 'Unable to update route',
            });
          }
          
        } catch (error) {
            console.log(error);
            setLoading(false);
            setAlert({
                status: true,
                type: 'error',
                message: error.response?.data?.message || 'Failed to update Routes.',
            });
            }
      };


  return (
    <Card color="transparent" shadow={false}>    
        {alert.status ? (
          <div className="w-1/2 m-6 ">
            <GeneralAlert props={{ ...alert, setAlert }} />
          </div>
        ) : null}
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Start Point
                        </Typography>
                        <Input
                            size="lg"
                            name="start_point"
                            value={routeData.start_point}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}                            
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Start Point Coordinates
                        </Typography>
                        <Input
                            size="lg"
                            name="start_point_coordinates"
                            value={formData.start_point_coordinates}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            End Point
                        </Typography>
                        <Input
                            size="lg"
                            name="end_point"
                            type="text"
                            value={routeData.end_point}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}                            
                        />
                    </div>  
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            End Point Coordinates
                        </Typography>
                        <Input
                            size="lg"
                            name="end_point_coordinates"
                            value={formData.end_point_coordinates}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Route Code
                        </Typography>
                        <Input
                            size="lg"
                            name="route_code"
                            type="text"
                            value={routeData.route_code}
                            onChange={handleInputChangeWithValidation}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}                            
                        />
                        {errors.route_code && (
                            <Typography variant="small" color="red">
                                {errors.route_code}
                            </Typography>
                        )}
                    </div>  
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Client
                        </Typography>

                        <Select
                            size="lg"
                            onChange={(value) => handleSelectChange('agency_id', value)}                            
                        >
                            { clients.length && clients.map(client => (
                                <Option value={client.id}>
                                    {client.name} 
                                </Option>
                            ))}
                        </Select> 
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Current Vehicle
                        </Typography>
                        <Input
                            size="lg"
                            name="vehicle_id"
                            type="text"
                            disabled
                            value={`${routeData.registration ? routeData.registration : ''} - ${routeData.fleet_number ? routeData.fleet_number : ''}`}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}                            
                        />                        
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Change Default Vehicle
                        </Typography>

                        <Select
                            size="lg"
                            onChange={(value) => handleSelectChange('vehicle_id', value)}                            
                        >
                            { vehicles.length && vehicles.map(vehicle => (
                                <Option value={vehicle.id}>
                                    {vehicle.registration} - {vehicle.fleet_number}
                                </Option>
                            ))}
                        </Select> 
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Vehicle Status
                        </Typography>
                        <Select
                            size="lg"
                            name="status"
                            value={routeData.status}
                            onChange={(value) => handleSelectChange('status', value)}                            
                        >
                                <Option value='ACTIVE'>Active</Option>
                                <Option value='INACTIVE'>Inactive</Option>
                        </Select> 
                    </div> 
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Route Length
                        </Typography>
                        <Input
                            size="lg"
                            name="estimate_length"
                            value={routeData.estimate_length}
                            type="number"
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}                            
                        />                       
                    </div> 
                    <div className="col-span-1">
                        <Button className={`mt-6 w-full`} type="submit" disabled={loading || Object.keys(errors).some((key) => errors[key])}>
                            {loading ? <Spinner color="white" size="sm" /> : 'Update'}
                        </Button>
                    </div>
                   
                </div>  
             
            </form>
    </Card>
  );
}

export default RouteManagement;
