import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchTripBookings } from "@/apis/trips"
const TripBookings = ({ id }) => {
  const [bookings, setBookings] = useState([]);
  const [statistics ,setStatistics ] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const bookings = await fetchTripBookings(token, id);
        if (bookings) {
          setBookings(bookings.bookings);
          setStatistics(bookings.statistics);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch bookings.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching bookings.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const headers = [ "Name", "Pick Up", "Drop off","Distance","Travel Date", "Session","Status","Trip Code","Cost",   ];
  const columns = [   "passengerName", "routeStartPoint", "stage", "distance_travelled","trip_date","trip_leg", "status","trip_code", "cost", ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        statistics={statistics}
        columns={columns}
        data={bookings || []}
        sortableColumns={[0, 1, 2, ]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        settingsColumn={false}
        hideCreateButton = {true}
      />
    </>
  );
};


export default TripBookings;
