import React, { useState, useEffect } from "react";
const API_KEY = import.meta.env.VITE_GOOGLEMAPS_API_KEY;
import sendSentryAlert from "@/utils/sentryUtil";

const determineLiveButtonColor = (status) => {
    try {
      let color;
  
      switch (status) {
        case "NEW":
          color = "blue";
          break;
        case "DECLINED":
        case "CANCELLED":
          color = "red";
          break;
        case "DISPATCHED":
          color = "yellow";
          break;
        case "LAYOVER":
          color = "orange";
          break;
        case "COMPLETED":
          color = "purple";
          break;
        case "ACTIVE":
            color ="green";
            break;
        default:
          color = "blue";
          break;
      }
      return color;
    } catch (err) {
      sendSentryAlert(err)
    }
  };
  
  
const ReUsableMapComponent = ({ origin, destination, route_code, status }) => {
  console.log(origin, destination, route_code)
  const [routeLength, setRouteLength] = useState(null);

  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/directions?key=${API_KEY}&origin=${origin}&destination=${destination}&avoid=tolls|highways`;

  // Fetch route length using Distance Matrix API
  useEffect(() => {
    if (origin && destination) {
      const distanceMatrixUrl = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${API_KEY}&units=imperial`;

      fetch(distanceMatrixUrl)
        .then(response => response.json())
        .then(data => {
          const distance = data.rows[0].elements[0].distance;
          if (distance) {
            setRouteLength(distance.text); // Distance in a readable format (e.g., "12.4 km")
          }
        })
        .catch(error => console.error("Error fetching distance matrix:", error));
    }
  }, [origin, destination]);

  return (
    <div className="App">
      <div className="p-2 text-left">
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Route Code <u>{route_code}</u>
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Origin : {origin}
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Destination: {destination}
        </p>
        {routeLength && (
          <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            Route Length: {routeLength}
          </p>
        )}
         <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Status: <span style={{ color: determineLiveButtonColor(status) }}>{ status }</span>
        </p>
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <iframe
          title="Google Maps Directions"
          width="100%"
          height="600"
          style={{ border: 0 }}
          src={googleMapsEmbedUrl}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ReUsableMapComponent;
