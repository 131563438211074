import React, { useEffect, useState } from "react";
import { Card, CardBody, Typography, Spinner, Button } from "@material-tailwind/react";
import MaintainDispatchComponent from "@/widgets/components/pagecomponents/trips/MaintainDispatchComponent";
import BookingsDetailsComponent from "@/widgets/components/pagecomponents/trips/BookingsDetailsComponent";
import LiveTrackDispatch from "@/widgets/components/pagecomponents/trips/liveTrackDispatchComponent";
import ReUsableMapComponent from "@/widgets/components/pagecomponents/trips/ReusableMapComponent" 
import { fetchDispatch, resendNotifications } from "@/apis/trips";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';
import { useParams, useNavigate } from 'react-router-dom';
import { MapPinIcon, ArrowLeftCircleIcon } from "@heroicons/react/24/solid";

export function DispatchDetails() {
  const [dispatch, setDispatch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [selectedNav, setSelectedNav] = useState('bookings');
  const { id } = useParams();
  const navigate = useNavigate();
  const [notificationLoading, setNotificationLoading] = useState();

  const determineLiveButtonColor = (status) => {
    try {
      console.log(status);
      let color;
  
      switch (status) {
        case "NEW":
          color = "blue-500";
          break;
        case "DECLINED":
        case "CANCELLED":
          color = "red-500";
          break;
        case "DISPATCHED":
          color = "yellow-500";
          break;
        case "LAYOVER":
          color = "orange-500";
          break;
        case "COMPLETED":
          color = "purple-500";
          break;
        case "ACTIVE":
            color ="green-500";
            break;
        default:
          color = "blue-500";
          break;
      }
  
      console.log(color);
      return color;
    } catch (err) {
      console.log(err);
    }
  };

  const handleResendNotifications = async () => {
    try{
      setNotificationLoading(true)
      const token = localStorage.getItem('token');
      const request = await resendNotifications(token, id);
      if(request){
        setAlert({
          status: true,
          message: 'Notifications have been resent successfully.',
          type:'success',
        });
      }else{
        setAlert({
          status: true,
          message: 'Failed to resend notifications.',
          type:'error',
        })
      }     
      setNotificationLoading(false)
    }catch (err) {
      setAlert({
        status: true,
        message: err.message,
        type:'error',
      })
    }
  }
  

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const dispatch = await fetchDispatch(token, id);
        if (dispatch) {
            setDispatch(dispatch);
            console.log('dispatch', dispatch);
          setLoading(false);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch dispatch at this time.",
            type: 'error',
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching dispatch. Try again.',
          type: 'error',
        });
      }
    };

    fetchData();
  }, []);

  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
  };
 let content;
  switch (selectedNav) {
    case 'bookings':
      content = <div>Bookings</div>;
      break;
    case 'management':
      content = <div>Maintenance</div>;
      break;
    case 'live':
      content = <div>Live</div>;
      break;
    default:
      content = null;
  }

  console.log("dispatch", dispatch)

  return (
    <div className="m-2">
      <div className="mb-4">    
        <div class="p-2 text-left">
          Dispatch Details
        </div>           
      </div>
      <div className="flex justify-between gap-8">
      <div class="p-2 text-left w-full">
          <p
            class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            Route {dispatch?.route?.name}
          </p>
          <p
            class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            Vehicle Serving: {dispatch?.route?.defaultVehicle?.fleet_number} - {dispatch?.route?.defaultVehicle?.registration}
          </p>
          <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            Confirmation status: {dispatch?.is_confirmed ? (<span className="font-bold text-white bg-green-500 px-2 py-1 m-2">Active</span>) : (<span className="font-bold text-white bg-red-500 px-2 py-1 m-2">Not Active</span>)}
          </p>
          <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            Activity status: <span className="font-bold px-2 py-1 m-2">{dispatch?.status}</span>
          </p>
        </div>
        <div className="w-full p-2 m-2">
          { notificationLoading ? (
              <Button disabled>
              <Spinner />
            </Button>
          ) : (
            <Button onClick={handleResendNotifications}>
            Resend Notifications
          </Button>
          ) }      

        </div>

      </div> 
      { alert.status ? (
        <div className="w-1/2 mx-auto">
          <GeneralAlert props={{ ...alert, setAlert }} />
        </div>
      ) : null }     
      <div className="mt-12 mb-8 flex flex-row gap-12">
        <div className="flex-shrink-0 w-38 bg-gray-100 py-4">
          <div className="flex flex-col gap-2">
          <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg 'bg-white text-gray-700`}
              onClick={() => navigate(-1)}
            >
              <ArrowLeftCircleIcon className="h-5 w-5 mr-2" />
              Back
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'bookings' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('bookings')}
            >
              <MapPinIcon className="h-5 w-5 mr-2" />
              Bookings
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg bg-${determineLiveButtonColor(dispatch.status)} text-black`}
              onClick={() => handleNavClick('live')}
            >
              <MapPinIcon className="h-5 w-5 mr-2" />
              Live
            </button>
            {dispatch?.status !== "Active" && (
              <button
                className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'management' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                onClick={() => handleNavClick('management')}
              >
                <MapPinIcon className="h-5 w-5 mr-2" />
                Management
            </button>
            )}
            
          </div>
        </div>
        <div className="flex-1 overflow-scroll">
          <Card>
            <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                {loading ? (
                  <Typography>
                    <Spinner />
                  </Typography>
                ) : (                 
                    <>                      
                    {selectedNav === 'bookings' && (
                        <div className="p-4">
                          <BookingsDetailsComponent dispatch={dispatch}/>
                        </div>
                      )}   
                      {selectedNav === 'management' && (dispatch?.status !== "Active") && (
                        <div className="p-4">
                          <MaintainDispatchComponent dispatch={dispatch}/>
                        </div>
                      )}   
                      {selectedNav === 'live' && (
                        <div className="p-4">
                          { dispatch.status === "ACTIVE" ? (
                            <LiveTrackDispatch dispatch={dispatch}/>
                          ):(
                            <ReUsableMapComponent 
                              origin={dispatch.route?.origin} 
                              destination={dispatch.route?.destination} 
                              route_code={dispatch.route?.code}
                              status={dispatch.status}
                            />
                          )}
                        </div>
                      )}  
                    </>
                )}
              </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DispatchDetails;
