// GeneralInformation.jsx
import React, { useState } from 'react';
import {
    Input,
    Button,
    Typography,
    Select,
    Option,
    Spinner
} from "@material-tailwind/react";
import  {updateUserDetailsFunction}  from "@/apis/auth";
import GeneralAlert from '@/widgets/alerts/general';
import { useNavigate } from 'react-router-dom';

export const GeneralInformation = ({ userDetails }) => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({ ...userDetails });
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setUserData({ ...userData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        try {
            const token = localStorage.getItem('token');
            const request_object = {
                ...userData
            }
            delete request_object.userLogs;
            delete request_object.twofaType;
            delete request_object.roles;
            const updateUser = await updateUserDetailsFunction(token, userData.id, request_object);
            if (updateUser) {
                navigate(-1);
            } else {
                setLoading(false);
                setAlert({
                    status: true,
                    message: 'Failed to update user.',
                    type: "error"
                });
            }
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
                type: "error"
            });
        }
    };

    return (
        <div>
            <Typography variant="h6" color="blue-gray" className="mb-3">
                General Information
            </Typography>
            {alert.status && (
                <div className="w-1/2 m-6">
                    <GeneralAlert props={{ ...alert, setAlert }} />
                </div>
            )}
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Username
                        </Typography>
                        <Input
                            size="lg"
                            name="username"
                            value={userData.username}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            User Email
                        </Typography>
                        <Input
                            size="lg"
                            name="email"
                            value={userData.email}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            disabled
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Mobile Number
                        </Typography>
                        <Input
                            size="lg"
                            name="mobileNumber"
                            value={userData.mobileNumber}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            User Status
                        </Typography>
                        <Select
                                size="lg"
                                name="status"
                                value={userData.status}
                                onChange={(value) => handleSelectChange('status', value)}
                                
                            >
                                <Option value="ACTIVE">Active</Option>
                                <Option value="INACTIVE">Inactive</Option>
                                <Option value="PENDING_APPROVAL">Pending Approval</Option>
                            </Select>
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            User Account type
                        </Typography>
                        <Select
                                size="lg"
                                name="account_type"
                                value={userData.account_type}
                                onChange={(value) => handleSelectChange('account_type', value)}
                                
                            >
                                <Option value="SECURITY">Security</Option>
                                <Option value="REGULAR">Regular</Option>
                            </Select>
                    </div>
                    {userData.roles === 'PASSENGER' && (
                        <div className="col-span-1">
                            <Typography variant="h6" color="blue-gray" className="mb-3">
                                Contract Expiry
                            </Typography>
                            <Input
                                type="date"
                                name="contract_expiry"
                                value={userData.contract_expiry ? new Date(userData.contract_expiry).toISOString().split('T')[0] : ''}
                                onChange={(e) => handleSelectChange('contract_expiry', e.target.value)}
                                className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            />                           
                        </div>
                    )}

                    {(userData.roles === 'ADMIN' || userData.roles === 'DISPATCH' || userData.roles === 'ACCOUNTS') && (
                        <div className="col-span-1">
                            <Typography variant="h6" color="blue-gray" className="mb-3">
                                Role
                            </Typography>
                            <Select
                                size="lg"
                                name="role"
                                value={userData.roles}
                                onChange={(value) => handleSelectChange('role', value)}
                                labelProps={{ className: "before:content-none after:content-none" }}
                                
                            >
                                <Option value="ADMIN">Admin</Option>
                                <Option value="DISPATCH">Dispatch</Option>
                                <Option value="ACCOUNTS">Accounts</Option>
                            </Select>
                        </div>
                    )}
                    {(userData.roles === 'CAPADMIN' || userData.roles === 'CAPAPROVER') && (
                        <div className="col-span-1">
                            <Typography variant="h6" color="blue-gray" className="mb-3">
                            Role
                            </Typography>
                            <Select
                                size="lg"
                                name="role"
                                value={userData.roles}
                                onChange={(value) => handleSelectChange('role', value)}
                                labelProps={{ className: "before:content-none after:content-none" }}
                                
                            >
                                <Option value="CAPADMIN">CAP Admin</Option>
                                <Option value="CAPAPROVER">CAP Approver</Option>
                            </Select>
                        </div>
                    )}
                      {(userData.roles === 'PASSENGER' || userData.roles === 'CAPAPROVER') && (
                       <div className="col-span-1">
                            <Typography variant="h6" color="blue-gray" className="mb-3">
                                Index Number
                            </Typography>
                            <Input
                                name="index_no"
                                value={userData.index_no}
                                onChange={(e) => handleSelectChange('index_no', e.target.value)}
                                className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            />                           
                        </div>
                    )}
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Update User'}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default GeneralInformation;
