import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import BookingsTableComponent from "@/widgets/components/pagecomponents/bookings/index";
import { fetchBookings, fetchOtherBookingsFunc } from "@/apis/bookings";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';

export function BookingsPage() {
  const [bookings, setBookings] = useState([]);
  const [otherBookings, setOtherBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [dataBreakdown, setDataBreakdown] = useState([]);
  const [otherDataBreakdown, setOtherDataBreakdown] = useState([]);
  const [activeTab, setActiveTab] = useState("current"); // Default active tab

  const fetchData = async () => {
    try {
      setAlert({ status: false, message: '', type: '' });
      setLoading(true);
      const token = localStorage.getItem('token');
      const bookings = await fetchBookings(token,1);
      if (bookings) {
          setBookings(bookings?.bookings);          
          setDataBreakdown(bookings?.dataBreakdown);          
      } else {
        setAlert({
          status: true,
          message: "Unable to fetch booking.",
          type: 'error',
        });
      }
    } catch (error) {
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: 'We are having trouble fetching booking.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchOtherBookings = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const bookings = await fetchOtherBookingsFunc(token, 1); 
      if (bookings) {
        setOtherBookings(bookings?.bookings);
        setOtherDataBreakdown(bookings?.dataBreakdown); 
      }
    } catch (error) {
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: 'We are having trouble fetching other bookings.',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when the page loads or when activeTab state changes
  useEffect(() => {
    if (activeTab === "current") {
      fetchData();
    }
    if (activeTab === "others") {
      fetchOtherBookings();
    }
  }, [activeTab]); // Runs on page load and whenever activeTab changes

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6">
          <Typography variant="h6" color="white">
            Bookings
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        
        {alert.status ? (
            <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
        ) : null }

        {/* Custom Tailwind Tabs */}
        <div className="flex space-x-4 border-b border-gray-300">
          <button 
            onClick={() => setActiveTab("current")} 
            className={`py-2 px-4 text-lg font-semibold ${activeTab === "current" ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-600 hover:text-blue-600"}`}>
            New 
          </button>
          <button 
            onClick={() => setActiveTab("others")} 
            className={`py-2 px-4 text-lg font-semibold ${activeTab === "others" ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-600 hover:text-blue-600"}`}>
            Processed 
          </button>
        </div>

        {/* Content Panels */}
        {loading ? (
          <div className="flex justify-center items-center h-full"><Spinner /></div>
        ) : (
          <div>
            {activeTab === "current" ? (
              <BookingsTableComponent 
                bookings={bookings || []} 
                dataBreakdown={dataBreakdown || []}
              />
            ) : (
              <BookingsTableComponent 
                bookings={otherBookings || []} 
                dataBreakdown={otherDataBreakdown || []}
              />
            )}
          </div>
        )}

        </CardBody>
      </Card>
    </div>
  );
}

export default BookingsPage;
