import React, { useEffect, useState } from "react";
import { Card, CardBody, Spinner } from "@material-tailwind/react";
import { UpdateStageFormComponent } from "@/widgets/components/pagecomponents/maintenances/updateStageFormComponent"
import { fetchStageDetailsFunc, updateStageFunc } from "@/apis/maintenances";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Cog8ToothIcon,
  ArrowLeftCircleIcon,
} from "@heroicons/react/24/solid";

export function StageDetailsComponent() {
  const [stage, setStage] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [selectedNav, setSelectedNav] = useState('management');
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const route = await fetchStageDetailsFunc(token, id);
      if (route) {
        setStage(route);
        setLoading(false);
      } else {
        setAlert({
          status: true,
          message: "Unable to fetch stage details at this time.",
          type: 'error',
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: 'We are having trouble fetching stage data. Try again.',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    

    fetchData();
  }, []);

  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert({ status: false, message: '', type: '' });
    const token = localStorage.getItem('token');
    try {
      const req_data = {
        name: stage.name,
        coordinates: stage.coordinates,
        distance_from_source:stage.distance_from_source,
        id:stage.id
      }
      const updateStageFunction = await updateStageFunc(token, req_data);
      if (updateStageFunction) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Stage updated successfully.',
          type: 'success',
        });
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to update stage.',
          type: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'Failed to update stage.',
        type: 'error',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStage({ ...stage, [name]: value });
  };

  if(loading){
    return (
        <Spinner />
    )
  }

  return (
    <div className="m-2">
      <div className="mb-4">
        <div class="p-2 text-left">
            <h6 class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Name: {stage?.name}
            </h6>
            <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                From: <span className="font-bold">{stage?.route?.start_point}</span> To <span className="font-bold">{stage?.route?.end_point}</span>
            </p>
            <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                Distance from {stage?.route?.start_point}: <span className="font-bold">{stage?.distance_from_source}</span> 
            </p>
        </div>
      </div>
      <div className="mt-12 mb-8 flex flex-row gap-12">
        <div className="flex-shrink-0 w-38 bg-gray-100 py-4">
          <div className="flex flex-col gap-2">
          <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg 'bg-white text-gray-700`}
              onClick={() => navigate(`/dashboard/routes/${stage.route.id}`)}
            >
              <ArrowLeftCircleIcon className="h-5 w-5 mr-2" />
              Back
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'management' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('management')}
            >
              <Cog8ToothIcon className="h-5 w-5 mr-2" />
              Management
            </button>    
          </div>
        </div>
        <div className="flex-1">
          <Card>
            <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                { alert.status ? (
                    <div className="w-1/2 mx-auto">
                      <GeneralAlert props={{ ...alert, setAlert }} />
                    </div>
                  ) : null }  
                     <UpdateStageFormComponent
                        formData={stage}
                        loading={loading}
                        handleInputChange={handleInputChange}
                        handleSubmit={handleSubmit}
                    />              
              </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default StageDetailsComponent;
